var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "APP_REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "PRODUCTION",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api.aiapait.kodefuse.com/v1",
            CHAT_URL: "https://api.aiapait.kodefuse.com"
        },
        DEVELOPMENT: {
            API_URL: "APP_API_URL"
        },
        QA: {
            API_URL: "https://qa-api.aiapait.kodefuse.com/v1",
            CHAT_URL: "https://qa-api.aiapait.kodefuse.com"
        },
        DEMO: {
            API_URL: "https://demo-api-aiapait.kodefuse.com/v1",
            CHAT_URL: "https://demo-api-aiapait.kodefuse.com"
        },
        PRODUCTION: {
            API_URL: "https://api.aiapait.com/v1",
            CHAT_URL: "https://api.aiapait.com"
        }
    }
};
Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;
